import axios from 'axios';
import {Tracer} from "../Tracer";

export class HttpUtils {

    constructor(){
    }

    static getHttpHeaders(contentType){

        const headers = {};

        if (contentType != null) {
            headers['content-type'] = contentType;
        }
        else{
            headers['content-type'] = 'application/json';
        }

        return headers;
    }



    static get(url) {
        return new Promise((resolve, reject) => {
            try {

                const headers = HttpUtils.getHttpHeaders();

                axios.get(url, {headers: headers}).then(response => {
                    HttpUtils.__resolve(response, resolve, reject);
                }).catch((e) => {
                    HttpUtils.__reject(e, resolve, reject, url);
                });
            } catch (err) {
                HttpUtils.__error(err, reject);
            }
        });
    }

    static post(url, data) {
        return new Promise((resolve, reject) => {
            try {

                const headers = HttpUtils.getHttpHeaders();

                axios.post(url, data, {headers: headers}).then(response => {
                    HttpUtils.__resolve(response, resolve, reject);
                }).catch((e) => {
                    HttpUtils.__reject(e, resolve, reject, url);
                });
            }
            catch (err) {
                HttpUtils.__error(err, reject);
            }
        });
    }

    static __resolve(response, resolve, reject) {
        if (response.status === 204) {
            resolve(true);
        }
        else if (response.status === 200) {

            if (response.data == null) {
                resolve({});
            }
            else {
                resolve(response.data);
            }

        } else if (response.status === 201 || response.status === 202 || response.status === 204) {

            if (response.data == null) {
                resolve(true);
            } else {
                resolve(response.data);
            }

        } else {

            Tracer.error(`414972d-(http)[status=${response.status}]: HTTP status not supported.`);

            const noSupportedError = new Error(`HTTP status ${response.status} not supported in HttpUtils.js`);
            reject(noSupportedError);
        }
    }

    static __reject(e, resolve, reject, url){
        if (e.response) {
            if (e.response.status === 404) {
                Tracer.debug('4917dcc-(http):[status=404]: Resource not found. url: ' + url);
                resolve(null);
                return;
            }
        }

        HttpUtils.__handleHttpError(e);
        reject(e);
    }

    static __error(err, reject){
        Tracer.error(err);
        reject(err);
    }

    static __handleHttpError(e){
        Tracer.error(e);
    }

}

